import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';



const sidebar = ( (err) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__sidebar' );

			if ( el.length === 0 ) { return false; }
		}

		initSidebar();

		// Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__sidebar' ) ) {
					initSidebar();
				}
			});
		}
	};

	const initSidebar = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize Flickity. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;

			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						sidebarClick();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initAccordion failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);

		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore we just initialize the carousels immediately.
			sidebarClick();
		}
	};

	const sidebarClick = function() {
		let 	scrollers = document.querySelectorAll( '.sidebar__scroll' );

		scrollers.forEach( function( scroller ) {
			scroller.addEventListener( 'click', function( e ) {
				let 	target = document.getElementById( this.dataset.target );

				target.scrollIntoView({
					behavior: 'smooth'
				});

				this.blur();
				e.preventDefault();
			});
		});
	}

	return {
		init: init
	}
})();



// Initialize
domReady( sidebar.init );
import.meta.webpackHot?.accept( sidebar.init ); // See https://webpack.js.org/api/hot-module-replacement