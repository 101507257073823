import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';

import mapboxgl from 'mapbox-gl';



const map = ( (err) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__map' );

			if ( el.length === 0 ) { return false; }
		}

		initMap();

		// Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__map' ) ) {
					initMap();
				}
			});
		}
	};

	const initMap = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize Flickity. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;

			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						buildMap();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initSlider failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);

		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore we just initialize the carousels immediately.
			buildMap();
		}
	};

	const buildMap = function() {
        let     maps = document.querySelectorAll( '.map' ),
                mapsArray = [];

        const   styleDefault = 'mapbox://styles/kyle-conrad/clpk9rdyb00eu01p48w8i7fdt';

        mapboxgl.accessToken = 'pk.eyJ1IjoiaW50ZXJuYXRpb25hbG1pZHdpdmVzIiwiYSI6ImNsczA1YWl0cjI1YnMyaXRlY3ptdnRtMmEifQ.Wp9EFXheXivGus_gGX-wiw';

        maps.forEach( function( single ) {
            const   id = single.id,
                    basejson = document.getElementById( id + '__script' ).innerHTML,
                    geojson = JSON.parse( basejson );
            
            const   minZoom = -1,
                    maxZoom = 4;
            
            const   bounds = [
                        [ -199.392729, -64.840765 ],
                        [ 213.466712, 84.807696 ]
                    ];

            let     markers = [];

            const   map = new mapboxgl.Map({
                        container: id,
                        style: styleDefault,
                        zoom: minZoom,
                        minZoom: minZoom,
                        maxZoom: maxZoom,
                        maxBounds: bounds,
                        cooperativeGestures: true,
                        attributionControl: false,
                        logoPosition: 'bottom-right',
                    });

            mapsArray.push( map );



            for ( const feature of geojson.features ) {
                const   el = document.createElement( 'div' );

                const   marker = new mapboxgl.Marker({
                            element: el,
                            className: 'map__marker',
                            anchor: 'bottom',
                            offset: [ 0, 0 ]
                        })
                        .setLngLat( feature.geometry.coordinates )
                        .setPopup(
                            new mapboxgl.Popup({
                                    offset: 36,
                                    closeButton: false,
                                    maxWidth: 'none'
                                })
                                .setHTML( feature.properties.infobox )
                            )
                        .addTo( map );
                
                markers.push( el );
            }

            map.addControl( new mapboxgl.NavigationControl(), 'bottom-left' );
            map.addControl( new mapboxgl.FullscreenControl(), 'bottom-left' );

            map.on( 'click', ( e ) => {
                let     marker = e.originalEvent.target.closest( '.mapboxgl-marker' );

                markers.forEach( function( marker ) {
                    marker.classList.remove( 'active' );
                });

                if ( marker ) { 
                    marker.classList.add( 'active' );
                }
            });
        });



        //// Listen for body class change to change map colors
        const   body = document.querySelector( 'body' );
        
        let     defaultColor = true;
          
        function bodyContrast( mutationList, observer ) {
            mutationList.forEach( function( mutation ) {
                if ( mutation.type === 'attributes' && mutation.attributeName === 'class' ) {
                    if ( mutation.target.classList.contains( 'contrast' ) ) {
                        defaultColor = false;

                        mapsArray.forEach( ( map ) => {
                            let     layers = map.getStyle().layers;

                            for ( let i = 0; i < layers.length; i++ ) {
                                if ( layers[ i ].id.includes( 'contrast-' ) ) {
                                    map.setPaintProperty( layers[ i ].id, layers[ i ].type + '-opacity', 1 );
                                }
                            }
                        });
                    }
                    else if ( !defaultColor ) {
                        mapsArray.forEach( ( map ) => {
                            let     layers = map.getStyle().layers;

                            for ( let i = 0; i < layers.length; i++ ) {
                                if ( layers[ i ].id.includes( 'contrast-' ) ) {
                                    map.setPaintProperty( layers[ i ].id, layers[ i ].type + '-opacity', 0 );
                                }
                            }
                        });

                        defaultColor = true;
                    }
                }
            })
        }
          
        const observer = new MutationObserver( bodyContrast );
        observer.observe( body, { attributes: true } );
	}

	return {
		init: init
	}
})();



// Initialize
domReady( map.init );
import.meta.webpackHot?.accept( map.init ); // See https://webpack.js.org/api/hot-module-replacement