import {domReady} from '@roots/sage/client';



const siteHeader = ( (err) => {
	if (err) { console.error(err); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit
		const el = document.querySelectorAll( '#nav' );
		if ( el.length === 0 ) { return false; }

		navSetup();
	};

	const navSetup = () => {
		const 	body = document.querySelector( 'body' ),
				nav = document.querySelector( '#nav' ),
				menu = document.querySelector( '#nav__menu' ),
				app = document.querySelector( '#app' );
		
		const	searchButton = document.querySelector( '#nav-btn__search' ),
				menuButton = document.querySelector( '#nav-btn__mobile' );

		const	menuWithChildren = document.querySelectorAll( 'li.menu-item-has-children' );
		
		const	dropdownButtons = document.querySelectorAll( '.btn--has-dropdown > .btn' );

		let 	meprAccountForm = document.querySelectorAll( '.mepr-account-form' ),
				commentReplyLinks = document.querySelectorAll( '.comment-reply-link' );

		let 	windowWidth = window.innerWidth,
				windowHeight = window.innerHeight;

		let 	navHeight = nav.offsetHeight,
				prevScrollPos = window.pageYOffset;

		let 	fontMin = 1,
				fontMax = 1.3;
		
		let 	loadScroll = document.querySelectorAll( '.load__scrollto' );

		document.querySelector(':root').style.setProperty( '--navHeight', navHeight + 'px' );

		// Widow control on load
		widowControl( windowWidth );

		// Adjust nav/menu variables once loaded fully
		document.onreadystatechange = () => {
			if ( document.readyState === 'complete' ) {
				app.classList.add( 'loaded' );
				
				navHeight = nav.offsetHeight;

				document.querySelector(':root').style.setProperty( '--navHeight', navHeight + 'px' );

				// Add scrollbar width variable when loaded
				document.documentElement.style.setProperty( '--scrollbar', ( window.innerWidth - document.documentElement.clientWidth ) + 'px');
			}
		};

		// Change variables/widows on window resize
		window.addEventListener( 'resize', function() {
			let 	newWindowWidth = window.innerWidth;

			navHeight = nav.offsetHeight;
			windowHeight = window.innerHeight;

			document.querySelector(':root').style.setProperty( '--navHeight', navHeight + 'px' );

			document.documentElement.style.setProperty( '--scrollbar', ( window.innerWidth - document.documentElement.clientWidth ) + 'px');

			if ( windowWidth != newWindowWidth ) {
				clearTimeout( window.resizedFinished );
	
				window.resizedFinished = setTimeout( function(){
					widowControl( newWindowWidth );

					windowWidth = window.innerWidth;
				}, 250 );
			}
		});

		// Scroll up to show menu
		window.addEventListener( 'scroll', function() {
			const currentScrollPos = window.pageYOffset;

			if ( prevScrollPos > currentScrollPos ) {
				body.classList.remove( 'scrolled' );
			} else {
				if ( currentScrollPos > ( navHeight * 1.5 ) ) {
					body.classList.add( 'scrolled' );
				}
			}

			prevScrollPos = currentScrollPos;
		});

		// Search button
		searchButton.addEventListener( 'click', function( e ) {
			dropdownButtons.forEach( function( button ) {
				button.classList.remove( 'active' );
			});
			
			body.classList.remove( 'nav--open' );
			body.classList.toggle( 'search--open' );

			this.blur();
			e.preventDefault();
		});

		// Hide search on menu hover
		menuWithChildren.forEach( function( menuItem ) {
			menuItem.addEventListener( 'mouseover', function( e ) {
				body.classList.remove( 'search--open' );
			});

			menuItem.addEventListener( 'focus', function( e ) {
				body.classList.remove( 'search--open' );
			});
		});

		// Mobile nav button
		menuButton.addEventListener( 'click', function( e ) {
			dropdownButtons.forEach( function( button ) {
				button.parentElement.classList.remove( 'active' );
			});
			
			body.classList.remove( 'search--open' );
			body.classList.toggle( 'nav--open' );

			this.blur();
			e.preventDefault();
		});

		// Site control buttons
		//// Font sizes
		document.querySelector( '#control-fontup' ).addEventListener( 'click', function( e ) {
			let 	fontCurrent = Number( getComputedStyle( document.documentElement ).getPropertyValue( '--fontvw' ).replace( 'vw', '' ) ),
					fontNew;

			if ( fontCurrent < fontMax ) {
				fontNew = Math.round( ( fontCurrent + 0.1 ) * 10 ) / 10;

				document.querySelector(':root').style.setProperty( '--fontsize', fontNew + 'rem' );
				document.querySelector(':root').style.setProperty( '--fontvw', fontNew + 'vw' );

				if ( fontNew >= fontMax ) {
					this.classList.add( 'disabled' );
				}
				if ( fontNew > fontMin ) {
					document.querySelector( '#control-fontreset' ).classList.remove( 'disabled' );
				}

				navHeight = nav.offsetHeight;

				document.querySelector(':root').style.setProperty( '--navHeight', navHeight + 'px' );

				document.documentElement.style.setProperty( '--scrollbar', ( window.innerWidth - document.documentElement.clientWidth ) + 'px');
			}

			this.blur();
		});

		document.querySelector( '#control-fontreset' ).addEventListener( 'click', function( e ) {
			let 	fontCurrent = Number( getComputedStyle( document.documentElement ).getPropertyValue( '--fontvw' ).replace( 'vw', '' ) ),
					fontNew;

			if ( fontCurrent > fontMin ) {
				fontNew = Math.round( ( fontMin ) * 10 ) / 10;

				document.querySelector(':root').style.setProperty( '--fontsize', fontNew + 'rem' );
				document.querySelector(':root').style.setProperty( '--fontvw', fontNew + 'vw' );

				if ( fontNew <= fontMin ) {
					this.classList.add( 'disabled' );
				}
				if ( fontNew < fontMax ) {
					document.querySelector( '#control-fontup' ).classList.remove( 'disabled' );
				}

				navHeight = nav.offsetHeight;

				document.querySelector(':root').style.setProperty( '--navHeight', navHeight + 'px' );

				document.documentElement.style.setProperty( '--scrollbar', ( window.innerWidth - document.documentElement.clientWidth ) + 'px');
			}

			this.blur();
		});

		//// Contrast
		document.querySelector( '#control-contrast' ).addEventListener( 'click', function( e ) {
			body.classList.toggle( 'contrast' );

			this.blur();
		});

		// Dropdown buttons
		dropdownButtons.forEach( function( button ) {
			button.addEventListener( 'click', function( e ) {
				const	canHover = window.matchMedia( '(hover: hover)' ).matches;

				if ( !canHover ) {
					dropdownButtons.forEach( function( other ) {
						if ( other != button ) {
							other.parentElement.classList.remove( 'active' );
						}
					});

					this.parentElement.classList.toggle( 'active' );
				}

				this.parentElement.blur();
				this.blur();
			});
		});

		// Turn off autocomplete on MemberPress account form
		meprAccountForm.forEach( function( form ) {
			let	inputs = form.querySelectorAll( 'input' );

			form.setAttribute( 'autocomplete', 'off' );
			form.setAttribute( 'aria-autocomplete', 'none' );

			inputs.forEach( function( input ) {
				input.setAttribute( 'autocomplete', 'nope' + Math.floor( Math.random() * 333 ) );
				input.setAttribute( 'aria-autocomplete', 'nope' + Math.floor( Math.random() * 333 ) );
			});
		});

		// Change comment reply title
		commentReplyLinks.forEach( function( link ) {
			let 	replyto = link.dataset.replyto;

			if ( replyto ) {
				link.dataset.replyto = replyto.replace( 'Reply to ', '' );
			}
		});

		// Scroll to listing block on load
		loadScroll.forEach( function( block ) {
			setTimeout( function() {
				const y = block.offsetTop;
	
				window.scrollTo({
					top: y,
					left: 0,
					behavior: 'smooth',
				});
			}, 250 );
		});
	};

	const widowControl = function( width ) {
		let 	widowElements = document.querySelectorAll( '.content p' );

		widowElements.forEach( function( element ) {
			element.innerHTML = element.innerHTML.replace( /&nbsp;/g, ' ' );
		});

		if ( width > 700 ) {
			widowElements.forEach( function( element ) {
				element.innerHTML = element.innerHTML.replace( /\s((?=(([^\s<>]|<[^>]*>)+))\2)\s*$/, '&nbsp;$1' );
			});
		}
	};

	return {
		init: init
	}
})();



// Initialize
domReady( siteHeader.init );
import.meta.webpackHot?.accept( siteHeader.init ); // See https://webpack.js.org/api/hot-module-replacement
