import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';



const slider = ( (err) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__slider' );

			if ( el.length === 0 ) { return false; }
		}

		initSlider();

		// Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__slider' ) ) {
					initSlider();
				}
			});
		}
	};

	const initSlider = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize Flickity. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;

			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						sliderFunctions();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initSlider failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);

		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore we just initialize the carousels immediately.
			sliderFunctions();
		}
	};

	const sliderFunctions = function() {
		let 	sliders = document.querySelectorAll( '.crd__slider' ),
				pagination = document.querySelectorAll( '.pagination' ),
				controls = document.querySelectorAll( '.control' );

		sliders.forEach( function( slider ) {
			slider.addEventListener( 'scrollend', function() {
				let 	slides = this.querySelectorAll( '.crd__slide' );

				slides.forEach( function( slide ) {
					let 	id = slide.id,
							rect = slide.getBoundingClientRect();

					if ( rect.left === 0 ) {
						slider.dataset.current = id;

						pagination.forEach( function( page ) {
							let 	pageSlide = page.dataset.slide;

							page.classList.remove( 'active' );

							if ( id === pageSlide ) {
								page.classList.add( 'active' );
							}
						});

						if ( slide === slide.parentElement.firstElementChild ) {
							controls.forEach( function( control ) {
								control.classList.remove( 'inactive' );

								if ( control.dataset.slider === slider.id && control.classList.contains( 'control--previous' ) ) {
									control.classList.add( 'inactive' );
								}
							});
						}
						else if ( slide === slide.parentElement.lastElementChild ) {
							controls.forEach( function( control ) {
								control.classList.remove( 'inactive' );
								
								if ( control.dataset.slider === slider.id && control.classList.contains( 'control--next' ) ) {
									control.classList.add( 'inactive' );
								}
							});
						}
						else {
							controls.forEach( function( control ) {
								control.classList.remove( 'inactive' );
							});
						}
					}
				});
			});
		});

		pagination.forEach( function( page ) {
			page.addEventListener( 'click', function() {
				let 	slider = document.getElementById( this.dataset.slider ),
						slide = document.getElementById( this.dataset.slide );
				
				let 	leftPos = slide.offsetLeft;
				
				slider.scroll({
					top: 0,
					left: leftPos,
					behavior: 'smooth',
				});

				page.blur();
				return false;
			});
		});

		controls.forEach( function( control ) {
			control.addEventListener( 'click', function() {
				let 	slider = document.getElementById( this.dataset.slider ),
						slide = document.getElementById( slider.dataset.current );

				if ( this.classList.contains( 'control--previous' ) ) {
					let 	prevPos = slide.previousElementSibling.offsetLeft;

					slider.scroll({
						top: 0,
						left: prevPos,
						behavior: 'smooth',
					});
				}
				else {
					let 	nextPos = slide.nextElementSibling.offsetLeft;

					slider.scroll({
						top: 0,
						left: nextPos,
						behavior: 'smooth',
					});
				}

				control.blur();
				return false;
			});
		});
	}

	return {
		init: init
	}
})();



// Initialize
domReady( slider.init );
import.meta.webpackHot?.accept( slider.init ); // See https://webpack.js.org/api/hot-module-replacement