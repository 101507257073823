import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';
import { Litepicker } from 'litepicker';



const filterbar = ( (err) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__filterbar' );

			if ( el.length === 0 ) { return false; }
		}

		initFilterBar();

		// Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__filterbar' ) ) {
					initFilterBar();
				}
			});
		}
	};

	const initFilterBar = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize Flickity. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;

			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						filterBarSetup();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initFilterBar failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);

		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore we just initialize the carousels immediately.
			filterBarSetup();
		}
	};

	const filterBarSetup = function() {
        let 	filterBars = document.querySelectorAll( '.has__filterbar' );

        filterBars.forEach( function( bar ) {
            let 	calendars = bar.querySelectorAll( '.calendar_picker' ),
                    labels = bar.querySelectorAll( 'label' );

            // Labels submit on click
            labels.forEach( function( label ) { 
                label.addEventListener( 'click', function( e ) {
					const	checkbox = this.querySelector( 'input' );

					e.preventDefault();

					if ( !checkbox.checked ) {
						checkbox.checked = true;
						checkbox.setAttribute( 'checked', 'checked' );
					}
					else {
						checkbox.checked = false;
						checkbox.setAttribute( 'checked', '' );
					}
					
                    bar.submit();
                });
            });

            // Build calendar and submit on date pick
            calendars.forEach( function( calendar ) {
                let     languageBase = calendar.dataset.language,
                        language = languageBase.replaceAll( '_', '-' );
                
                let     startInput = document.getElementById( calendar.dataset.targetStart ),
                        endInput = document.getElementById( calendar.dataset.targetEnd );
                
                let     startDate = calendar.dataset.dateStart,
                        endDate = calendar.dataset.dateEnd;

                const   picker = new Litepicker({
                            element: calendar,
                            inlineMode: true,
                            singleMode: false,
                            showTooltip: false,
                            numberOfMonths: 2,
                            numberOfColumns: 2,
                            format: 'DD-MM-YYYY',
                            lang: language,
                            startDate: startDate,
                            endDate: endDate
                        });

                picker.on( 'selected', ( date1, date2 ) => {
                    startInput.value = date1.dateInstance.toLocaleDateString( 'en-GB' ).replaceAll( '/', '-' );
                    endInput.value = date2.dateInstance.toLocaleDateString( 'en-GB' ).replaceAll( '/', '-' );

                    bar.submit();
                });
            });
        });
	}

	return {
		init: init
	}
})();



// Initialize
domReady( filterbar.init );
import.meta.webpackHot?.accept( filterbar.init ); // See https://webpack.js.org/api/hot-module-replacement