import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';



const filter = ( (err) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__filter' );

			if ( el.length === 0 ) { return false; }
		}

		initFilters();

		// Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__filter' ) ) {
					initFilters();
				}
			});
		}
	};

	const initFilters = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize Flickity. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;

			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						filterClick();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initFilters failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);

		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore we just initialize the carousels immediately.
			filterClick();
		}
	};

	const filterClick = function() {
		let 	filters = document.querySelectorAll( '.filter' );

		filters.forEach( function( filter ) {
			filter.addEventListener( 'click', function( e ) {
				let		container = this.closest( '.has__filter' ),
						filterName = this.dataset.target,
						targets = container.querySelectorAll( '.filter__target' );

				targets.forEach( function( target ) {
					let 	filterList = target.dataset.filter,
							filterArray = filterList.split( ',' );
					
					if ( filterArray.includes( filterName ) ) {
						target.classList.remove( 'hidden' );
					}
					else {
						target.classList.add( 'hidden' );
					}
				});

				filters.forEach( function( filter ) {
					filter.classList.remove( 'active' );
				});

				this.classList.add( 'active' );

				this.blur();
				e.preventDefault();
			});
		});
	}

	return {
		init: init
	}
})();



// Initialize
domReady( filter.init );
import.meta.webpackHot?.accept( filter.init ); // See https://webpack.js.org/api/hot-module-replacement