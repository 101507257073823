import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';



const ajax = ( (err) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__ajax' );

			if ( el.length === 0 ) { return false; }
		}

		initAjax();

		// Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__ajax' ) ) {
					initAjax();
				}
			});
		}
	};

	const initAjax = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize Flickity. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;

			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						ajaxSetup();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initAjax failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);

		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore we just initialize the carousels immediately.
			ajaxSetup();
		}
	};

	const ajaxSetup = function() {
		const	ajaxUrl = window.location.origin + '/wp-admin/admin-ajax.php';

		ajaxPages( ajaxUrl );
		ajaxFilter( ajaxUrl );
	}

	const ajaxPages = function( url ) {
		const	pageNav = document.querySelectorAll( '.page-navigation--ajax' );

		pageNav.forEach( function( nav ) {
			let	target = document.getElementById( nav.dataset.target ),
				query = target.dataset.query,
				template = target.dataset.template;
			
			let ajaxData = new FormData();
				ajaxData.append( 'action', 'ajax_pages' );
				ajaxData.append( 'query', query );
				ajaxData.append( 'template', template );

			let	pageLinks = nav.querySelectorAll( 'a' );

			ajaxLinks( pageLinks, url, ajaxData, target, nav );
		});
	}

	const ajaxLinks = function( links, url, ajax, target, nav ) {
		links.forEach( function( link ) {
			link.addEventListener( 'click', function( e ) {
				e.preventDefault();

				let	newPage = this.innerHTML;

				ajax.append( 'page', newPage );

				fetch( url, {
						method: 'POST',
						body: ajax,
					})
					.then( response => response.json() )
					.then( function( json ) {
						let pageHtml = json[ 0 ],
							navHtml = json[ 1 ];
						
						target.innerHTML = pageHtml;
						nav.innerHTML = navHtml;

						ajaxPages( url );
					});

				this.blur();
			});
		});
	}

	const ajaxFilter = function( url  ) {
		const	filters = document.querySelectorAll( '.filter--ajax' );

		filters.forEach( function( filter ) {
			let	target = document.getElementById( filter.dataset.target ),
				nav = document.getElementById( filter.dataset.target + '__nav' ),
				query = target.dataset.query,
				template = target.dataset.template;
			
			let taxonomy = filter.dataset.taxonomy,
				value = filter.dataset.value;
			
			let ajaxData = new FormData();
				ajaxData.append( 'action', 'ajax_filter' );
				ajaxData.append( 'query', query );
				ajaxData.append( 'taxonomy', taxonomy );
				ajaxData.append( 'value', value );
				ajaxData.append( 'template', template );
			
			filter.addEventListener( 'click', function( e ) {
				e.preventDefault();

				fetch( url, {
						method: 'POST',
						body: ajaxData,
					})
					.then( response => response.json() )
					.then( function( json ) {
						console.log( json );

						let filterHtml = json[ 0 ],
							filterCount = json[ 1 ],
							navHtml = json[ 2 ],
							filterQuery = json[ 3 ];
						
						target.innerHTML = filterHtml;

						if ( filterCount > 1 ) {
							nav.classList.remove( 'display--none' );

							nav.innerHTML = navHtml;
						}
						else {
							nav.classList.add( 'display--none' );
						}

						target.dataset.query = filterQuery;

						filters.forEach( function( other ) {
							other.classList.remove( 'active' );
						});
						filter.classList.add( 'active' );

						ajaxPages( url );
					});

				this.blur();
			});
		});
	}

	return {
		init: init
	}
})();



// Initialize
domReady( ajax.init );
import.meta.webpackHot?.accept( ajax.init ); // See https://webpack.js.org/api/hot-module-replacement