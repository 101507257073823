import { domReady } from '@roots/sage/client';


// Import scripts for specific views
import './views/sections/site-header.js';
import './views/sections/site-footer.js';

import './views/blocks/hero.js';
import './views/blocks/accordion.js';
import './views/blocks/ajax.js';
import './views/blocks/filter.js';
import './views/blocks/filterbar.js';
import './views/blocks/sidebar.js';
import './views/blocks/slider.js';
import './views/blocks/map.js';





/**
 * app.main
 * JS to run across the entire site. Use this sparingly! Most of your JS
 * should be confined into various /views/ JS partials to maintain
 * organization / encapsulation.
 */
const main = async (err) => {
	if ( err ) { console.error(err); } // handle hmr errors
};





/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady( main );
import.meta.webpackHot?.accept( main );