import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';



const hero = ( (err) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.hero' );

			if ( el.length === 0 ) { return false; }
		}

		initHero();

		// Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'hero' ) ) {
					initHero();
				}
			});
		}
	};

	const initHero = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize Flickity. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;

			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						heroSetup();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initHero failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);

		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore we just initialize the carousels immediately.
			heroSetup();
		}
	};

	const heroSetup = function() {
        const	emailInput = document.querySelector( '#hero__email' ),
                emailMerge = '&MERGE0=',
                emailButton = document.querySelector( '#hero__email-signup' );

		if ( typeof( emailInput ) != 'undefined' && emailInput != null ) {
			emailInput.addEventListener( 'input', function() {
				let 	email = this.value,
						emailUrl = emailButton.href + emailMerge + email;
	
				if ( email.length > 0 ) {
					emailButton.href = emailUrl;
				}
				else {
					emailButton.href = emailHref;
				}
			});
		}
	}

	return {
		init: init
	}
})();



// Initialize
domReady( hero.init );
import.meta.webpackHot?.accept( hero.init ); // See https://webpack.js.org/api/hot-module-replacement