import {domReady} from '@roots/sage/client';



const siteFooter = ( (err) => {
	if (err) { console.error(err); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit
		const el = document.querySelectorAll( '#footer' );
		if ( el.length === 0 ) { return false; }

		footerSetup();
	};

	const footerSetup = () => {
		const	emailInput = document.querySelector( '#footer__email' ),
				emailMerge = '&MERGE0=',
				emailButton = document.querySelector( '#footer__email-signup' ),
				emailHref = emailButton.href;

		emailInput.addEventListener( 'input', function() {
			let 	email = this.value,
					emailUrl = emailHref + emailMerge + email;

			if ( email.length > 0 ) {
				emailButton.href = emailUrl;
			}
			else {
				emailButton.href = emailHref;
			}
		});
	};

	return {
		init: init
	}
})();



// Initialize
domReady( siteFooter.init );
import.meta.webpackHot?.accept( siteFooter.init ); // See https://webpack.js.org/api/hot-module-replacement
